import { Button, Divider, Row } from "antd"
import Paragraph from "antd/es/typography/Paragraph"

export interface SlideStepContainerProps {
    showAtStep:number;
    currentStep:number;
    children: React.ReactNode
}

export const SlideStepContainer = (props: SlideStepContainerProps): JSX.Element => {
    if(props.currentStep >= props.showAtStep)
    {
        return <>
            {props.children}
        </>
    }
    else{
        return <>
        </>
    }
    
}