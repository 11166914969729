
import { Col, Row, Typography} from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { PowerPointSlideProps } from '../PowerpointComponents/PowerPoint';
import { SlideStepContainer } from '../PowerpointComponents/SlideStepContainer';

const { Paragraph, Text } = Typography;


export const ArduinoSlide = (props: PowerPointSlideProps): JSX.Element => 
{
    return <Col span={24}>
        <Row>
            <Col span={12} offset={2}>
                <Title style={{fontSize:"45pt"}} >Vad är Arduino?</Title>
            </Col>
        </Row>
        {/* <Row gutter={[0,50]}>
            <Col span={12} offset={2}>
            </Col>
            <Col span={12} offset={2}>
                <Text style={{fontSize:"22pt"}}>Programmerbart kretskort för att styra elektriska prylar.</Text>
            </Col>
        </Row> */}
        <Row gutter={[0,50]}>
            <Col span={24} offset={2}>
                <SlideStepContainer showAtStep={1} currentStep={props.slideStep ?? 0} >
                    <Text style={{fontSize:"30pt"}}>&#x2022; Programmerbart kretskort för att styra elektriska prylar.</Text>
                </SlideStepContainer>
            </Col>
            <Col span={24} offset={2}>
                <SlideStepContainer showAtStep={2} currentStep={props.slideStep ?? 0} >
                    <Text style={{fontSize:"30pt"}}>&#x2022; Programmeras i en simpliferad variant av C++</Text>
                </SlideStepContainer>
            </Col>
        </Row>
    </Col>
}