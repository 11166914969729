
import { Col, Image, Row } from 'antd';
import Title from 'antd/es/typography/Title';

interface TypeImageProps {
    image: string,
    text: string,
}

const TypeImage = (props: TypeImageProps): JSX.Element => {
    return <Col span={8} offset={0}>
        <Row justify={"space-around"} align={"middle"}>
            <Image preview={false} src={props.image} />
        </Row>
        <Row style={{ "fontSize": "25pt","backgroundColor" : "#FFF" }} justify={"space-around"} align={"middle"}>
            {props.text}
        </Row>
    </Col>
}

export const ArduinoTypesSlide = (): JSX.Element => {
    return <Col span={24} style={{"backgroundColor" : "#FFF"}}>
        <Row gutter={[0, 50]} style={{marginBottom:"100px"}}>
            <Col span={12} offset={2}>
                <Title style={{ fontSize: "45pt" }} >Varianter av Arduino</Title>
            </Col>
        </Row>
        <Row gutter={[0, 75]}>
            <Col>
            </Col>
            <Col span={24} offset={0}>
                <Row gutter={0} justify={"space-around"} align={"middle"}>
                    <TypeImage image="arduinonano.jpg" text="Nano" />
                    <TypeImage image="arduinouno.jpg" text="Uno" />
                    <TypeImage image="arduinomega.jpg" text="Mega" />
                </Row>
            </Col>
        </Row>
    </Col>
}