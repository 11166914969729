/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: crimsonfalcon (https://sketchfab.com/crimsonfalcon)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/arduino-uno-board-f31feafc5e9743abbdf33c54f9d92669
title: Arduino Uno Board
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { useFrame } from '@react-three/fiber'

type GLTFResult = GLTF & {
  nodes: {
    Arduino010_0: THREE.Mesh
    Arduino010_1: THREE.Mesh
    Arduino010_2: THREE.Mesh
    Arduino009_0: THREE.Mesh
    Arduino009_1: THREE.Mesh
    Arduino008_0: THREE.Mesh
    Arduino008_1: THREE.Mesh
    Arduino008_2: THREE.Mesh
    Arduino008_3: THREE.Mesh
    Arduino008_4: THREE.Mesh
    Arduino006_0: THREE.Mesh
    Arduino006_1: THREE.Mesh
    Arduino006_2: THREE.Mesh
    Arduino006_3: THREE.Mesh
    Arduino005_0: THREE.Mesh
    Arduino005_1: THREE.Mesh
    Arduino005_2: THREE.Mesh
    Arduino007_0: THREE.Mesh
    Arduino004_0: THREE.Mesh
    Arduino003_0: THREE.Mesh
    Plane001_0: THREE.Mesh
    Arduino002_0: THREE.Mesh
    Plane003_0: THREE.Mesh
    Arduino001_0: THREE.Mesh
    ['@Floor_0']: THREE.Mesh
    Arduino011_0: THREE.Mesh
  }
  materials: {
    ['Arduino_Uno.001']: THREE.MeshStandardMaterial
    ['Arduino_Uno.001.side']: THREE.MeshStandardMaterial
    ['Arduino_Uno.001.side2']: THREE.MeshStandardMaterial
    ['Arduino_Uno.002_usb']: THREE.MeshStandardMaterial
    ['Arduino_Uno.001.side.USB']: THREE.MeshStandardMaterial
    ['Arduino_Uno.001.side2.USB']: THREE.MeshStandardMaterial
    ['Arduino_Uno.001.back']: THREE.MeshStandardMaterial
    Floor: THREE.MeshStandardMaterial
  }
}

export function ArduinoUnoBoard(props: JSX.IntrinsicElements['group']) {


  const arduinoRef = React.useRef<THREE.Group | null>(null);

  useFrame(({ clock }) => {
    if (arduinoRef && arduinoRef.current) {
      arduinoRef.current.rotation.x = clock.getElapsedTime()*0.5
      arduinoRef.current.rotation.y = clock.getElapsedTime()*0.25
      // arduinoRef.current.rotation.z = clock.getElapsedTime()*-0.5
    }
  })


  const { nodes, materials } = useGLTF('/assets/arduino_uno_board.glb') as any
  return (
    <group ref={arduinoRef} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.33}>
        <group position={[1.42, -1.37, 0.39]} scale={[1.85, 0.35, 1.29]}>
          <mesh geometry={nodes.Arduino010_0.geometry} material={materials['Arduino_Uno.001']} />
          <mesh geometry={nodes.Arduino010_1.geometry} material={materials['Arduino_Uno.001.side']} />
          <mesh geometry={nodes.Arduino010_2.geometry} material={materials['Arduino_Uno.001.side2']} />
        </group>
        <group position={[0.47, -0.23, 0]}>
          <mesh geometry={nodes.Arduino009_0.geometry} material={materials['Arduino_Uno.001']} />
          <mesh geometry={nodes.Arduino009_1.geometry} material={materials['Arduino_Uno.001.side']} />
        </group>
        <group position={[0.47, -0.23, 0]}>
          <mesh geometry={nodes.Arduino008_0.geometry} material={materials['Arduino_Uno.001']} />
          <mesh geometry={nodes.Arduino008_1.geometry} material={materials['Arduino_Uno.002_usb']} />
          <mesh geometry={nodes.Arduino008_2.geometry} material={materials['Arduino_Uno.001.side.USB']} />
          <mesh geometry={nodes.Arduino008_3.geometry} material={materials['Arduino_Uno.001.side2.USB']} />
          <mesh geometry={nodes.Arduino008_4.geometry} material={materials['Arduino_Uno.001.back']} />
        </group>
        <group position={[0, 0, -0.04]}>
          <mesh geometry={nodes.Arduino006_0.geometry} material={materials['Arduino_Uno.001']} />
          <mesh geometry={nodes.Arduino006_1.geometry} material={materials['Arduino_Uno.001.back']} />
          <mesh geometry={nodes.Arduino006_2.geometry} material={materials['Arduino_Uno.001.side']} />
          <mesh geometry={nodes.Arduino006_3.geometry} material={materials['Arduino_Uno.001.side2']} />
        </group>
        <mesh geometry={nodes.Arduino005_0.geometry} material={materials['Arduino_Uno.001']} />
        <mesh geometry={nodes.Arduino005_1.geometry} material={materials['Arduino_Uno.001.side2.USB']} />
        <mesh geometry={nodes.Arduino005_2.geometry} material={materials['Arduino_Uno.001.side']} />
        <group position={[0.03, 0.02, 0]}>
          <mesh geometry={nodes.Arduino007_0.geometry} material={materials['Arduino_Uno.001']} />
        </group>
        <mesh geometry={nodes.Arduino004_0.geometry} material={materials['Arduino_Uno.001']} />
        <mesh geometry={nodes.Arduino003_0.geometry} material={materials['Arduino_Uno.001']} />
        <group position={[6.9, 1.01, 5.9]} rotation={[-0.27, 0.6, 1.93]} />
        <mesh geometry={nodes.Plane001_0.geometry} material={materials['Arduino_Uno.001.side2']} />
        <mesh geometry={nodes.Arduino002_0.geometry} material={materials['Arduino_Uno.001']} />
        <group position={[-4.23, -6.18, -1.27]} />
        <group position={[-1.55, -1.87, 0]} rotation={[0, 0, Math.PI / 2]}>
          <mesh geometry={nodes.Plane003_0.geometry} material={materials['Arduino_Uno.001.side2']} />
          <mesh geometry={nodes.Arduino001_0.geometry} material={materials['Arduino_Uno.001']} />
        </group>
        <group position={[0, 0, -0.04]}>
          <mesh geometry={nodes.Arduino011_0.geometry} material={materials['Arduino_Uno.001.back']} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/assets/arduino_uno_board.glb')
