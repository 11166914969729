import { Button, Row } from "antd"
import { Arduino } from "../../Models/Arduino"
import { PowerPoint, PowerPointProps, PowerPointSlide } from "../PowerpointComponents/PowerPoint"
import { AgendaSlide } from "../Sliders/AgendaSlide"
import { ArduinoPinsSlide } from "../Sliders/ArduinoPinsSlide"
import { ArduinoSlide } from "../Sliders/ArduinoSlide"
import { ArduinoTypesSlide } from "../Sliders/ArduinoTypesSlide"
import { GroupsSlide } from "../Sliders/GroupsSlide"
import { IntroductionToArdunioSlide } from "../Sliders/IntroductionToArduinoSlide"
import { ServoSlide } from "../Sliders/ServoSlide"
import { TinkercadSlide } from "../Sliders/TinkercadSlide"

export const HomeView = (): JSX.Element => {

    const slides: PowerPointSlide[] = [
        {
            component: <IntroductionToArdunioSlide />,
            presses: 1,
        },
        {
            component: <AgendaSlide slideStep={0} />,
            presses: 1,
        },
        {
            component: <ArduinoSlide slideStep={0} />,
            presses: 3,
        },
        {
            component: <ArduinoTypesSlide />,
            presses: 1,
        },
        {
            component: <ArduinoPinsSlide slideStep={4} />,
            presses: 1,
        },
        {
            component: <ServoSlide />,
            presses: 1,
        },
        {
            component: <TinkercadSlide />,
            presses: 1,
        },
        {
            component: <GroupsSlide slideStep={0} />,
            presses: 1,
        }
    ]


    return <Row>
        <PowerPoint slides={slides} />
    </Row>
}