
import { Col, Image, Row,} from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';


export const TinkercadSlide = (): JSX.Element => 
{
    return <Col span={24}>
        <Row>
            <Col span={12} offset={2}>
                <Title style={{fontSize:"45pt"}} >Tinkercad</Title>
            </Col>
        </Row>
        <Row gutter={[0,50]} >
            <Col span={20} offset={2}>
                <div style={
                    {
                        width:"100%",
                        display:"flex",
                        justifyContent:"center"
                    }
                }>
                    <a href='https://www.tinkercad.com/dashboard' target="_blank">
                        <Image preview={false} src="/assets/tinkercad.png" />
                    </a>
                </div>
            </Col>
        </Row>
    </Col>
}