import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppLayout from './Components/layout/AppLayout';
import { HomeView } from './Components/views/HomeView';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { InstructionsView } from './Components/views/InstructionsView';

function App() {
  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/instructions" element={<InstructionsView />} />
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
}

export default App;

