import React from 'react';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, MenuProps, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

export interface AppLayoutProps {
    children: React.ReactNode;
}

interface LayoutLink {
    path: string;
    text: string;
    icon: any;
}

const AppLayout = ({ children }: AppLayoutProps): JSX.Element => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const links: LayoutLink[] = [
        { icon: UserOutlined, path: "", text: "Hem" },
        { icon: UserOutlined, path: "/instructions", text: "Instruktioner" },
        { icon: UserOutlined, path: "/other", text: "Övrigt" },
    ];

    const items1: MenuProps['items'] = ['1', '2', '3'].map((key) => ({
        key,
        label: `nav ${key}`,
    }));

    const items2: MenuProps['items'] =[
        {
            key: 'center',
            label: (<Link to="">Home</Link>),
        },
        {
            key: 'settings',
            label: (<Link to="/instructions">Instruktioner</Link>),
        }
    ];

    return (
        <Layout>
            <Layout>
                <Header style={{ background: colorBgContainer }}>
                    <Menu mode="horizontal" items={items2}/>
                </Header>
                {/* {links.map((link, index) => <Link to={link.path}>{link.text}</Link>)} */}

                <Content style={{ margin: '24px 16px 0' }}>
                    {children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
            </Layout>
        </Layout>
    );
};

export default AppLayout;