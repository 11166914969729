/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Body1_11_1: THREE.Mesh
    Body1_11_2: THREE.Mesh
    Body1_11_3: THREE.Mesh
    Body1_11_4: THREE.Mesh
    Body1_11_5: THREE.Mesh
    Body1_11_6: THREE.Mesh
    Body1_11_7: THREE.Mesh
  }
  materials: {
    ['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_0_0_255_']: THREE.MeshStandardMaterial
    ['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_64_64_64_']: THREE.MeshStandardMaterial
    ['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_165_132_0_']: THREE.MeshStandardMaterial
    ['SG90 - Micro Servo 9g - Tower Pro v1_Steel___Satin']: THREE.MeshStandardMaterial
    ['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_140_92_8_']: THREE.MeshStandardMaterial
    ['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_255_0_0_']: THREE.MeshStandardMaterial
    ['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_255_140_0_']: THREE.MeshStandardMaterial
  }
}

export function ServoBody(props: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/assets/ServoBody.glb') as any
  return (
    <group {...props} dispose={null}>
      <group position={[0.07, -0.07, 0]}>
        <mesh geometry={nodes.Body1_11_1.geometry} material={materials['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_0_0_255_']} />
        <mesh geometry={nodes.Body1_11_2.geometry} material={materials['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_64_64_64_']} />
        <mesh geometry={nodes.Body1_11_3.geometry} material={materials['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_165_132_0_']} />
        <mesh geometry={nodes.Body1_11_4.geometry} material={materials['SG90 - Micro Servo 9g - Tower Pro v1_Steel___Satin']} />
        <mesh geometry={nodes.Body1_11_5.geometry} material={materials['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_140_92_8_']} />
        <mesh geometry={nodes.Body1_11_6.geometry} material={materials['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_255_0_0_']} />
        <mesh geometry={nodes.Body1_11_7.geometry} material={materials['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_255_140_0_']} />
      </group>
    </group>
  )
}

useGLTF.preload('/assets/ServoBody.glb')
