import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { Col, Row, } from 'antd';
import Title from 'antd/es/typography/Title';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import React from "react";
import { Knob } from "../Knob";
import { ServoBody } from "../../Models/ServoBody";
import { ServoHead } from "../../Models/ServoHead";
import { PerspectiveCamera } from "three";


function useOnScreen(ref: RefObject<HTMLElement>) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref]);


    useEffect(() => {
        if (ref.current != null) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}


const ObjModel: React.FC = () => {
    const materials = useLoader(MTLLoader, '/assets/arduino.mtl');
    const obj = useLoader(OBJLoader, '/assets/arduino.obj', (loader) => {
        materials.preload();
        loader.setMaterials(materials);
    });

    console.log(obj);
    return <primitive object={obj} scale={1} />;
};

const CameraController = () => {
    const { camera, gl } = useThree();
    useEffect(
        () => {
            const controls = new OrbitControls(camera, gl.domElement);
            controls.enableZoom = false;
            controls.minDistance = 3;
            controls.maxDistance = 5;
            return () => {
                controls.dispose();
            };
        },
        [camera, gl]
    );
    return null;
};

const DisableRender = () => useFrame(() => null, 1000);


export const ServoSlide = (): JSX.Element => {

    const [servoRotation,setServoRotation] = useState<number>(0);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const onKnobChange = (value:number) => {
        if(value > 180 && value <= 360)
        {
            return;
        }
        value = ((90 - value) % 360)-90;
        setServoRotation((value * (Math.PI/180)));
    }

    const degrees90 = Math.PI/2


    return <Col span={24}>
        <Row style={{paddingTop:"0.5px"}}>
            <div style={{ position: "relative" }}>
                {
                    isVisible ? <Canvas style={{ position: "absolute", width: "100vw", height: "100vh" }}>
                    {!isVisible && <DisableRender />}
                    <CameraController />
                    <ambientLight />
                    <pointLight position={[10, 10, 10]} />
                    {/* <ArduinoUnoBoard position={[2.0, 0.0, 0]} rotation={[0,2,0]} scale={[3,3,3]} /> */}
                    <ServoBody position={[2.0, 0.0, 0]} rotation={[degrees90,0,0]} scale={[3,3,3]}/>
                    <ServoHead servoRotation={servoRotation} position={[2.0, 0.0, 0]} rotation={[degrees90,0,0]} scale={[3,3,3]}/>
                </Canvas> : <div></div>
                }
                
                <div ref={ref} style={{ position: "absolute", width: "100vw", height: "100vh","pointerEvents" : "none" }}>
                    <Col span={24}>
                        <Row style={{ height: "100vh" }} align={"middle"}>
                            <Col span={24} offset={6}>
                                <Knob onChange={onKnobChange} max={180} min={0} />
                            </Col>
                        </Row>
                    </Col>
                </div>
            </div>

        </Row>
    </Col>
}