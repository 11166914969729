import { Progress, Space, SpaceProps, Typography } from "antd"
import { CompoundedComponent } from "antd/es/float-button/interface";
import { forwardRef, useEffect, useRef, useState } from "react"

export interface KnobProps {
    min: number,
    max: number,
    onChange: (value: number) => void
}

interface Center {
    x: number;
    y: number;
}



export const Knob = (props: KnobProps): JSX.Element => {

    const [angle, setAngle] = useState<number>(0);
    const [isMouseDown, setMouseDown] = useState<boolean>(false);
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [isMouseDown]);



    const onMouseMove = (e: MouseEvent) => {
        if (divRef.current && isMouseDown) {
            const rect = divRef.current.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            const deltaX = e.clientX - centerX;
            const deltaY = e.clientY - centerY;
            let angle = Math.atan2(deltaY, deltaX) * 180 / Math.PI;
            if (angle < 0) {
                angle += 360;
            }
            else if (angle > 360) {
                angle -= 360;
            }
            setAngle(angle);
            props.onChange(angle);
        }
    }

    const onMouseDown = () => {
        setMouseDown(true);
    }

    const onMouseUp = () => {
        setMouseDown(false);
    }

    return <Space onMouseDown={onMouseDown} onMouseUp={onMouseUp} style={{ pointerEvents: "all" }} >
        <div ref={divRef} style={{ "position": "relative" }}>
            <Progress
                style={{ transform: `rotate(${angle - 21 + 90}deg)` }}
                steps={360}
                format={() => ""}
                type="circle"
                percent={15}
                showInfo={true}
                strokeWidth={15}
            />
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)"
            }} >
                <Typography style={{ fontSize: "25pt" }}>{Math.trunc(angle)}</Typography>
            </div>
        </div>
    </Space>




}