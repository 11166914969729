
import { Col, Image, Row, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useState } from 'react';
import { PowerPointSlideProps } from '../PowerpointComponents/PowerPoint';
import { SlideStepContainer } from '../PowerpointComponents/SlideStepContainer';

const { Paragraph, Text } = Typography;

interface CircleProps {
    x: number;
    y: number;
    radius: number;
}

const Circle = (props: CircleProps): JSX.Element => {
    return <div style={{
        position: "absolute",
        top: props.y,
        left: props.x,
        width: props.radius + "px",
        height: props.radius + "px",
        borderRadius: "50%",
        borderStyle: "solid",
        borderWidth:"10px",
        borderColor:"rgba(0,255,0,1.0)",
        backgroundColor: "rgba(0,0,0,0.0)",
        pointerEvents: "none",
    }}>
    </div>
}

export const ArduinoPinsSlide = (props: PowerPointSlideProps): JSX.Element => {

    const [overlayPosition, setOverlayPosition] = useState({ x: -9999, y: -9999 });
    const [isMouseDown, setIsMouseDown] = useState(false);

    const radius = 350;

    const handleImageClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsMouseDown(true);
        const { x, y } = event.nativeEvent;
        const imageRect = event.currentTarget.getBoundingClientRect(); // get position of image
        setOverlayPosition({ x: x - imageRect.left - radius / 2, y: y - imageRect.top - radius / 2 }); // subtract image position from mouse position
    };

    const onMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsMouseDown(false);
    }

    return <Col span={24}>
        <Row>
            <Col span={22} offset={2}>
                <Title style={{ fontSize: "45pt" }} >Vad finns på en Arduino Uno?</Title>
            </Col>
        </Row>
        <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            pointerEvents: "none",
        }}
            draggable={true}
        >
            <Row gutter={[0, 0]} justify="center" align={"middle"}>
                <Col span={24} offset={0}>
                    <div style={
                        {
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                        }
                    }>
                        <Image
                            onMouseDown={handleImageClick}
                            // onMouseMove={onMouseMove}
                            onMouseUp={onMouseUp}
                            height={"80vh"}
                            preview={false}
                            style={{pointerEvents:"all"}}
                            src="/assets/ArduinoUnoPins.png"
                        />
                        <Circle radius={radius} x={overlayPosition.x} y={overlayPosition.y} />
                    </div>
                </Col>
            </Row>
        </div>

    </Col>
}