import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { Col, Row, } from 'antd';
import Title from 'antd/es/typography/Title';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Mesh } from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { Arduino } from "../../Models/Arduino";
import { ArduinoUnoBoard } from "../../Models/ArduinoUnoBoard";
import React from "react";

const ObjModel: React.FC = () => {
    const materials = useLoader(MTLLoader, '/assets/arduino.mtl');
    const obj = useLoader(OBJLoader, '/assets/arduino.obj', (loader) => {
        materials.preload();
        loader.setMaterials(materials);
    });

    console.log(obj);
    return <primitive object={obj} scale={1} />;
};

function useOnScreen(ref: RefObject<HTMLElement>) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref])


    useEffect(() => {
        if (ref.current != null) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}



export const IntroductionToArdunioSlide = (): JSX.Element => {

    const DisableRender = () => useFrame(() => null, 1000);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const CameraController = () => {
        const { camera, gl } = useThree();
        useEffect(
            () => {
                const controls = new OrbitControls(camera, gl.domElement);
                controls.minDistance = 3;
                controls.maxDistance = 5;
                return () => {
                    controls.dispose();
                };
            },
            [camera, gl]
        );
        return null;
    };

    if (!isVisible) {

        // return <Col span={24}>
        //     <Row>
        //         <div >
        //             <div style={{ position: "relative" }}>
        //                 <div style={{ position: "absolute", width: "100vw", height: "100vh" }}>
        //                     <p></p>
        //                 </div>
        //             </div>
        //         </div>
        //     </Row>
        // </Col>
    }


    return <Col span={24}>
        <Row>
            <div >
                <div style={{ position: "relative" }}>
                    {
                        isVisible ?
                        <Canvas style={{ position: "absolute", width: "100vw", height: "100vh" }}>
                        {!isVisible && <DisableRender />}
                        {/* <CameraController/> */}
                        <ambientLight />
                        <pointLight position={[10, 10, 10]} />
                        <ArduinoUnoBoard position={[2.0, 0.0, 0]} scale={[3, 3, 3]} />
                        </Canvas> : <div></div>
                    }
                    <div ref={ref} style={{ position: "absolute", width: "100vw", height: "100vh" }}>
                        <Col span={24}>
                            <Row style={{ height: "100vh" }} align={"middle"}>
                                <Col span={24} offset={2}>
                                    <Title style={{ fontSize: "55pt", margin: "0" }}>Introduktion till Arduino</Title>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </div>
            </div>
        </Row>
    </Col>
}