import { Col, Row,Slider,theme } from 'antd';
import React, { useEffect } from 'react';
import { useRef, useState } from 'react';

export interface PowerPointProps {
    slides: PowerPointSlide[]
}

export interface PowerPointSlide {
    component: React.ReactNode;
    presses: number;
}

export interface PowerPointSlideProps {
    slideStep : number | null;
}

const { useToken } = theme;

export const PowerPoint = (props: PowerPointProps): JSX.Element => {
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const { token } = useToken();
    const root = useRef<HTMLDivElement | null>(null);
    const childrenRef = useRef<HTMLDivElement[]>([]);
    const [index, setIndex] = useState(0);
    const [slideStep, setSlideStep] = useState(10);
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'ArrowRight') {
            var currentSlide = props.slides[index];
            if(slideStep+1 < currentSlide.presses)
            {
                setSlideStep(prevStep => prevStep +1);
                //Todo update internal data in slide
            }
            else
            {
                childrenRef.current[index+1].scrollIntoView({ block: 'end',  behavior: 'smooth' });
                setIndex(prevIndex => prevIndex + 1);
                setSlideStep(0); //Reset internal steps
            }
        }
        if (event.key === 'ArrowLeft') {
            childrenRef.current[index - 1].scrollIntoView({ block: 'end',  behavior: 'smooth' });;
            setIndex(prevIndex => prevIndex - 1);
            setSlideStep(0);
        }
        if (event.keyCode === 113) {
            root.current?.requestFullscreen();
            setSlideStep(0);
            setIndex(0);
            setIsFullscreen(true);
            childrenRef.current[0].scrollIntoView({ block: 'end',  behavior: 'smooth' });;
            // setIndex(0);
        }
    };

    const fullscreenStyle : React.CSSProperties= {
        backgroundColor: token.colorBgElevated,
        overflowY: "scroll",
        overflowX: "hidden",
        height: "100vh",
        width: "100vw",
    }
    const defaultStyle : React.CSSProperties = {
        overflowX: "hidden",
        margin: '-24px -16px 0'
    }
    const style = isFullscreen ? fullscreenStyle : defaultStyle;

    useEffect(() => {
        const handleFullscreenChange = () => {
          if (!document.fullscreenElement) {
            setSlideStep(10);
            setIsFullscreen(false);
          }
        };
        root.current?.addEventListener("fullscreenchange", handleFullscreenChange);
      
        return () => {
            root.current?.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
      }, []);

    return (
        <Row className='noScrollBar' style={style} ref={(el: HTMLDivElement | null) => (root.current = el as HTMLDivElement)} onKeyDown={handleKeyDown} tabIndex={0}>
            {props.slides.map((x,i) => <Col key={i} span={24} style={{ height: "100vh", width: "100vw",overflow:"hidden" }} ref={(el: HTMLDivElement | null) => (childrenRef.current[i] = el as HTMLDivElement)}>
                {/* {x.component} */}
                {React.cloneElement(x.component as any, { slideStep: slideStep })}
            </Col>)}
        </Row>
    );
};