import { Col, Divider, Row, Steps } from "antd"
import { useState } from "react";
import { InstallArduinoIdeStep } from "../instructions/InstallArduinoIdeStep";
import { ArduionoInstructionStep } from "../instructions/ArduionoInstructionStep";
import { HomeView } from "./HomeView";
import { NameStep } from "../instructions/NameStep";
import { ArduinoServoWireStep } from "../instructions/ArduinoServoWireStep";
import { Extender5VStep } from "../instructions/5VExtenderStep";
import { ArduinoServoWireStep2 } from "../instructions/ArduinoServoWireStep2";
import { IdeSetComPortStep } from "../instructions/IdeSetComPortStep";
import { CodeStep } from "../instructions/CodeStep";
import { UploadCodeStep } from "../instructions/UploadCodeStep";
import { GooglyEyeStep } from "../instructions/GooglyEyeStep";
import { ArduinoServoWireStepCarousel } from "../instructions/ArduinoServoWireStepCarousel";
import { BatteryStep } from "../instructions/BatteryStep";

export const InstructionsView = (): JSX.Element => {
    const [current, setCurrent] = useState(0);
    const onChange = (value:number) => {
        console.log('onChange:', current);
        setCurrent(value);
    };

    return <Row>
        <Steps
            direction="vertical"
            size="default"
            current={current}
            onChange={onChange}
            items={[
                { title: 'Installera Arduino Ide', description: <InstallArduinoIdeStep /> },
                { title: 'Kom på ett namn', description: <NameStep /> },
                { title: 'Montera roboten enligt instruktionerna i rutan nedan', description: <ArduionoInstructionStep /> },
                // { title: 'Koppla in den här komponenten i 5V-pinen på arduinon', description: <Extender5VStep /> },
                { title: 'Nu ska vi koppla in servosen i arduinon', description: <ArduinoServoWireStepCarousel /> },
                // { title: 'Nu ska vi koppla in servosen i arduinon. Vi börjar med den bakre servon.', description: <ArduinoServoWireStep /> },
                // { title: 'Nu ska vi koppla in den främre servon i arduinon.', description: <ArduinoServoWireStep2 /> },
                { title: 'Koppla in arduinon i en usb-port på datorn med den blå-genomskinliga sladden och starta Arduino IDE.', description: <Row><Divider /></Row> },
                { title: 'I Arduino IDE så välj rätt COM-port och board-typ', description: <IdeSetComPortStep/> },
                { title: 'Skriv den här koden i IDE:en', description: <CodeStep asset="ArduinoCode.ini" /> },
                { title: 'Ladda upp koden på arduinon', description: <UploadCodeStep /> },
                { title: 'Sätt på googly eyes', description: <GooglyEyeStep /> },
                { title: 'Ryck usb-kabeln', description: <BatteryStep /> },
            ]}
        />
    </Row>
}