import axios from "axios";
import { useEffect, useState } from "react";

const assetConfig = {
    domain: window.location.origin,
    assets: "/assets/",
}

const getFullPath = (path: string) => {
    return assetConfig.domain + path;
}

export const useTextAsset = (asset:string) : [string,boolean] => {
    const [text, setText] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    

    useEffect(() => {
        setLoading(true);
        axios.get<boolean>(getFullPath(assetConfig.assets) + asset)
        .then(res => {
            setText(res.data.toString())
            setLoading(false);
        })
    },[]);
    return [text,loading];
}