import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Canvas, ThreeElements, useFrame, useThree } from "@react-three/fiber"
import { Button, Col, Divider, Row, Slider } from "antd"
import { SliderMarks } from "antd/es/slider";
import Paragraph from "antd/es/typography/Paragraph"
import { RefObject, Suspense, useEffect, useMemo, useRef, useState } from "react"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Arduino } from "../../Models/Arduino";
import { Arduinoanimated2 } from "../../Models/Arduinoanimated2";
// import { Arduinoanimated } from "../../Models/Arduinoanimated";
import { ArduinoAnimatedJS } from "../../Models/ArduinoAnimatedJS";
import { ArduinoRobotInstructions } from "../../Models/ArduinoRobotInstructions";
import { ArduinoRobotInstructions2 } from "../../Models/ArduinoRobotInstructions2";

interface ArduionoInstructionStepProps {
    startAnimationTime: number,
    maxAnimationTime: number,
    animationIndex: number,
    extraPauseDelay: number,
    name: string,
}

const steps: ArduionoInstructionStepProps[] = [
    { animationIndex: 0, startAnimationTime: 0.5, maxAnimationTime: 2.5,  name: "Montera servosen i robotchassit. Klicka på pilen till höger för att se nästa steg.", extraPauseDelay: 0.5 },
    { animationIndex: 1, startAnimationTime: 3, maxAnimationTime: 6,  name: "Sätt på chassi-sidan", extraPauseDelay: 0 },
    { animationIndex: 2, startAnimationTime: 7, maxAnimationTime: 11,  name: "Kläm fast chassi-sidan med dom stora chassiklämmorna. Tänk på att sätta dom åt rätt håll så batteriet får plats emellan senare", extraPauseDelay: 0.25 },
    { animationIndex: 3, startAnimationTime: 11.2, maxAnimationTime: 13, name: "Lägg batteriet mellan chassi-klämmorna", extraPauseDelay: 0 },
    { animationIndex: 4, startAnimationTime: 13.4, maxAnimationTime: 15, name: "Skjut in arduinon mellan chassi-klämmorna", extraPauseDelay: 0.25 },
    { animationIndex: 5, startAnimationTime: 15.5, maxAnimationTime: 19.5, name: "Skruva fast bakbenen i bakre servon med den lilla skruven som jämt försvinner. Skruven ligger i plastpåsen", extraPauseDelay: 0 },
    { animationIndex: 6, startAnimationTime: 20, maxAnimationTime: 23, name: "Skruva fast frambenen i den främre servon", extraPauseDelay: 0 },
    { animationIndex: 7, startAnimationTime: 25, maxAnimationTime: 28, name: "Kläm fast chassi-sidan med dom små chassi-klämmorna. Scrolla sedan vidare till nästa steg", extraPauseDelay: 0 },
];

function useOnScreen(ref: RefObject<HTMLElement>) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ), [ref]);


    useEffect(() => {
        if (ref.current != null) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [])

    return isIntersecting
}

const DisableRender = () => useFrame(() => null, 1000);


export const ArduionoInstructionStep = (): JSX.Element => {


    const [currentInstructionStep, setCurrentInstructionStep] = useState<number>(0);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const currentInstruction = steps[currentInstructionStep];

    const CameraController = () => {
        const { camera, gl } = useThree();
        useEffect(
            () => {
                const controls = new OrbitControls(camera, gl.domElement);
                controls.enableZoom = false;
                controls.minDistance = 3;
                controls.maxDistance = 4;
                return () => {
                    controls.dispose();
                };
            },
            [camera, gl]
        );
        return null;
    };

    const nextAnimation = () => {
        if (currentInstructionStep < steps.length - 1) {
            setCurrentInstructionStep(currentInstructionStep + 1);
        }
    }

    const previousAnimation = () => {
        if (currentInstructionStep > 0) {
            setCurrentInstructionStep(currentInstructionStep - 1);
        }
    }

    const onSliderChange = (sliderValue:number) => {
        setCurrentInstructionStep(sliderValue);
    }



    const sliderMax = steps.map(x => x.maxAnimationTime - x.startAnimationTime).reduce((a,b) => a+b,0);

    const marks: SliderMarks = {
    };
    let offset = 0;
    steps.map((x,i) => {
        marks[i] = " ";
        offset = x.maxAnimationTime;
    });



    return <Row gutter={[16, 16]}>
        <Col span={18} offset={3}>
            <div ref={ref} style={{ height: "500px", position: "relative" }}>
                <Canvas style={{ position: "absolute" }}>
                    {!isVisible && <DisableRender />}
                    <CameraController />
                    <ambientLight />
                    <pointLight position={[10, 10, 10]} />
                    <Suspense fallback={null}>
                        <ArduinoRobotInstructions2
                            animationIndex={currentInstruction.animationIndex}
                            maxAnimationTime={currentInstruction.maxAnimationTime}
                            startAnimationTime={currentInstruction.startAnimationTime}
                            extraPauseDelay={currentInstruction.extraPauseDelay}
                            position={[0, 0, 0]}
                        />
                    </Suspense>
                    {/* <Stats showPanel={0} /> */}
                </Canvas>
                <div style={{ position: "absolute", width: "100%", height: "100%", pointerEvents: "none" }}>
                    <Row style={{ height: "100%" }} justify="center" align="middle">
                        <Col span={2}>
                            <Row justify="center" align="middle">
                                <Button onClick={previousAnimation} style={{ pointerEvents: "all" }} icon={<LeftOutlined />}></Button>
                            </Row>
                        </Col>
                        <Col span={20} style={{ pointerEvents: "none", height: "100%" }}>
                            <Row justify="center" align="bottom" style={{ height: "100%" }} >
                                <Col span={24}>
                                </Col>
                                <Col span={24} style={{ pointerEvents: "all" }}>
                                    <Paragraph style={{textAlign:"center"}}>{currentInstruction.name}</Paragraph>
                                    <Slider onChange={onSliderChange} value={currentInstructionStep} step={1} max={steps.length-1} marks={marks}></Slider>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={2}>
                            <Row justify="center" align="middle">
                                <Button onClick={nextAnimation} style={{ pointerEvents: "all" }} icon={<RightOutlined />}></Button>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            
        </Col>
        <Divider />
    </Row>
}