import { Col, Divider, Image, Row, Switch, Typography } from "antd"

const { Paragraph, Text } = Typography;

export const UploadCodeStep = (): JSX.Element => {
    return <Row>
        <Col span={24}>
            <Row>
                <Image preview={false} src="ArduinoUploadCode.png" />
            </Row>
        </Col>
        <Col span={24}>
            <Row>
                <Col span={24}>
                    <Text>Klicka på pilen för att kompilera och ladda upp koden på arduinon. Pröva gärna att ändra i koden.</Text>
                </Col>
                <Col span={24}>
                    <Text italic> Om benen sitter lite snett kan ni behöva skriva värdet 90 till servosen, skruva av benen och skruva tillbaka dom fast rakt. Anledningen till ni ska skriva just 90 är för att det är då servon befinner sig i sitt mittenläge.</Text>
                </Col>
            </Row>
        </Col>
        <Divider />
    </Row>
}