
import { Col, Row, Space, Typography} from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { PowerPointSlideProps } from '../PowerpointComponents/PowerPoint';
const { Paragraph, Text } = Typography;

export const AgendaSlide = (props: PowerPointSlideProps): JSX.Element => 
{
    return <Col span={24}>
        <Row>
            <Col span={12} offset={2}>
                <Title style={{fontSize:"45pt"}} >Agenda</Title>
            </Col>
        </Row>
        <Row gutter={[0,50]}>
            <Col span={12} offset={2}>
            </Col>
            <Col span={12} offset={2}>
                <Text style={{fontSize:"22pt"}}>&#x2022; Vad är Arduino</Text>
            </Col>
            <Col span={12} offset={2}>
                <Text style={{fontSize:"22pt"}}>&#x2022; Servo</Text>
            </Col>
            <Col span={12} offset={2}>
                <Text style={{fontSize:"22pt"}}>&#x2022; Tinkercad</Text>
            </Col>
            <Col span={12} offset={2}>
                <Text style={{fontSize:"22pt"}}>&#x2022; Gruppindelning</Text>
            </Col>
        </Row>
    </Col>
}