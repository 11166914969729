import { Col, Divider, Row, Spin } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import { CopyBlock, dracula as theme } from "react-code-blocks"
import { useTextAsset } from "../../Services/AssetService";

export interface CodeStepProps {
    asset: string;
}

export const CodeStep = (props: CodeStepProps): JSX.Element => {

    const [code, loading] = useTextAsset(props.asset);

    if (loading) {
        return <Spin></Spin>
    }


    return <Row>
        <Col span={24}>
            <CopyBlock
                text={code}
                language={"cpp"}
                showLineNumbers={false}
                startingLineNumber={0}
                wrapLines
                theme={theme}
            />
        </Col>
        <Divider />
    </Row>
}