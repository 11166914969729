/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { AnimationClip } from 'three'
import { GroupProps, useFrame } from '@react-three/fiber';

interface ArduinoRobotInstructionsProps extends GroupProps {
  startAnimationTime: number,
  maxAnimationTime: number,
  animationIndex: number,
  extraPauseDelay: number,
}

type GLTFResult = GLTF & {
  nodes: {
    Body_V1: THREE.Mesh
    BackLegs_1: THREE.SkinnedMesh
    BackLegs_2: THREE.SkinnedMesh
    BackLegs_3: THREE.SkinnedMesh
    FrontLegs_1: THREE.SkinnedMesh
    FrontLegs_2: THREE.SkinnedMesh
    FrontLegs_3: THREE.SkinnedMesh
    FrontArduinoClamp: THREE.SkinnedMesh
    Lock: THREE.SkinnedMesh
    BackClamp: THREE.SkinnedMesh
    ServoBody_1: THREE.SkinnedMesh
    ServoBody_2: THREE.SkinnedMesh
    ServoBody_3: THREE.SkinnedMesh
    ServoBody_4: THREE.SkinnedMesh
    ServoBody_5: THREE.SkinnedMesh
    ServoBody_6: THREE.SkinnedMesh
    ServoWire_1: THREE.SkinnedMesh
    ServoWire_2: THREE.SkinnedMesh
    ServoWire_3: THREE.SkinnedMesh
    ServoConnector: THREE.SkinnedMesh
    FrontServoBody_1: THREE.SkinnedMesh
    FrontServoBody_2: THREE.SkinnedMesh
    FrontServoBody_3: THREE.SkinnedMesh
    FrontServoBody_4: THREE.SkinnedMesh
    FrontServoBody_5: THREE.SkinnedMesh
    FrontServoBody_6: THREE.SkinnedMesh
    FrontServoWire_1: THREE.SkinnedMesh
    FrontServoWire_2: THREE.SkinnedMesh
    FrontServoWire_3: THREE.SkinnedMesh
    FrontServoConnector: THREE.SkinnedMesh
    ArduinoClamp2: THREE.SkinnedMesh
    Battery_1: THREE.SkinnedMesh
    Battery_2: THREE.SkinnedMesh
    Battery_3: THREE.SkinnedMesh
    Battery_4: THREE.SkinnedMesh
    Arduino_Blue2_1: THREE.SkinnedMesh
    Arduino_Blue2_2: THREE.SkinnedMesh
    ScrewDriver_1: THREE.SkinnedMesh
    ScrewDriver_2: THREE.SkinnedMesh
    ScrewDriver_3: THREE.SkinnedMesh
    ScrewDriver_4: THREE.SkinnedMesh
    UnderClamp01: THREE.SkinnedMesh
    ['root-bone-added']: THREE.Bone
    ['root-bone-added_(1)']: THREE.Bone
    ['root-bone-added_(2)']: THREE.Bone
    ['root-bone-added_(3)']: THREE.Bone
    ['root-bone-added_(4)']: THREE.Bone
    ['root-bone-added_(5)']: THREE.Bone
    BackServoBone01: THREE.Bone
    ['root-bone-added_(6)']: THREE.Bone
    ['root-bone-added_(7)']: THREE.Bone
    ['root-bone-added_(8)']: THREE.Bone
    ['root-bone-added_(9)']: THREE.Bone
    ['root-bone-added_(10)']: THREE.Bone
    ['root-bone-added_(11)']: THREE.Bone
  }
  materials: {
    ['Material #677']: THREE.MeshStandardMaterial
    ['9VBattery_Material']: THREE.MeshStandardMaterial
    ['9VBattery_Material_006']: THREE.MeshStandardMaterial
    ['Material #677']: THREE.MeshStandardMaterial
    Steel___Satin: THREE.MeshStandardMaterial
    Opaque_0_0_255_: THREE.MeshStandardMaterial
    Opaque_64_64_64_: THREE.MeshStandardMaterial
    Opaque_165_132_0_: THREE.MeshStandardMaterial
    Opaque_198_193_188_: THREE.MeshStandardMaterial
    Opaque_255_255_255_: THREE.MeshStandardMaterial
    ['Material #680']: THREE.MeshStandardMaterial
    ['Material #681']: THREE.MeshStandardMaterial
    ['Material #682']: THREE.MeshStandardMaterial
    ['Material #541']: THREE.MeshStandardMaterial
    ['9VBattery_Material_002']: THREE.MeshStandardMaterial
    ['19 - Default']: THREE.MeshStandardMaterial
    ['20 - Default']: THREE.MeshStandardMaterial
    ['Material #678']: THREE.MeshStandardMaterial
    ['Material #679']: THREE.MeshStandardMaterial
  }
}

type ActionName = 'All Animations'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export function ArduinoRobotInstructions2(props: ArduinoRobotInstructionsProps) {
  const group = useRef<any>()
  const { nodes, materials, animations } = useGLTF('/assets/ArduinoRobotInstructions2.glb') as GLTFResult
  const { actions, names } = useAnimations<AnimationClip>(animations, group)

  const kalle = props;
  useEffect(() => {
    if (actions && names && names.length > 0) {
      // actions[names[0]]?.startAt(6.8);
      var animation = actions[names[0]];
      if (animation) {
        animation.paused = false;
        animation.play();
      }
      // actions[names[0]]?.reset().fadeIn(0.5).play();
      // actions[names[0]]?.setDuration(400);
      fixFrustumCulled();
    }
  })


  useFrame(() => {
    // console.log(actions[names[0]]?.time);
    var animation = actions[names[0]];
    if (animation) {
      if (animation.time >= props.maxAnimationTime) {
        if (props.extraPauseDelay == 0) {
          animation.time = props.startAnimationTime;
          animation.paused = false;
        }
        else {
          if(!animation.paused)
          {
            setTimeout(() => {
              if (animation) {
                if (animation.time >= props.maxAnimationTime) {
                  animation.time = props.startAnimationTime;
                }
                animation.paused = false;
              }
            }, props.extraPauseDelay * 1000);
          }
          animation.paused = true;
        }


      }
      else if (animation.time < props.startAnimationTime) {
        animation.paused = false;
        animation.time = props.startAnimationTime;
      }
    }

    hideModels();
  })

  const hideModels = () => {
    if (group.current instanceof THREE.Group) {
      var threeGroup = group.current as THREE.Group;
      threeGroup.traverse((model) => {

        if (model instanceof THREE.SkinnedMesh) {
          var skinnedMesh = (model as THREE.SkinnedMesh);
          skinnedMesh.frustumCulled = false;
          skinnedMesh.skeleton.bones.forEach(x => {
            if (x.position.length() > 100) {
              skinnedMesh.visible = false;
            }
            else {
              skinnedMesh.visible = true;
            }
          });
        }
      });
    }
  }

  const fixFrustumCulled = () => {
    if (group.current instanceof THREE.Group) {
      var threeGroup = group.current as THREE.Group;
      threeGroup.traverse((model) => {
        if (model instanceof THREE.Object3D) {
          (model as THREE.Object3D).frustumCulled = false;
          // console.log(model);
        }
        if (model instanceof THREE.SkinnedMesh) {
          var skinnedMesh = (model as THREE.SkinnedMesh);
          skinnedMesh.frustumCulled = false;
          // skinnedMesh.skeleton.bones.forEach(x => {
          //   console.log(x.position);

          // });
        }
        if (model instanceof THREE.Bone) {
          (model as THREE.Bone).frustumCulled = false;
        }
        if (model instanceof THREE.Group) {
          (model as THREE.Group).frustumCulled = false;
        }
      });

    }
  };

  return (
    <group frustumCulled={false} ref={group} {...props} dispose={null}>
      <group>
        <primitive object={nodes['root-bone-added']} />
        <primitive object={nodes['root-bone-added_(1)']} />
        <primitive object={nodes['root-bone-added_(2)']} />
        <primitive object={nodes['root-bone-added_(3)']} />
        <primitive object={nodes['root-bone-added_(4)']} />
        <primitive object={nodes['root-bone-added_(5)']} />
        <primitive object={nodes.BackServoBone01} />
        <primitive object={nodes['root-bone-added_(6)']} />
        <primitive object={nodes['root-bone-added_(7)']} />
        <primitive object={nodes['root-bone-added_(8)']} />
        <primitive object={nodes['root-bone-added_(9)']} />
        <primitive object={nodes['root-bone-added_(10)']} />
        <primitive object={nodes['root-bone-added_(11)']} />
        <mesh name="Body_V1" geometry={nodes.Body_V1.geometry} material={materials['Material #677']} position={[-0.89, 0, -0.24]} />
        <group name="BackLegs" position={[0.43, -0.18, 0.24]}>
          <skinnedMesh name="BackLegs_1" geometry={nodes.BackLegs_1.geometry} material={materials['9VBattery_Material']} skeleton={nodes.BackLegs_1.skeleton} />
          <skinnedMesh name="BackLegs_2" geometry={nodes.BackLegs_2.geometry} material={materials['9VBattery_Material_006']} skeleton={nodes.BackLegs_2.skeleton} />
          <skinnedMesh name="BackLegs_3" geometry={nodes.BackLegs_3.geometry} material={materials['Material #677']} skeleton={nodes.BackLegs_3.skeleton} />
        </group>
        <group name="FrontLegs" position={[2.51, 0.28, 0.23]}>
          <skinnedMesh name="FrontLegs_1" geometry={nodes.FrontLegs_1.geometry} material={materials['9VBattery_Material']} skeleton={nodes.FrontLegs_1.skeleton} />
          <skinnedMesh name="FrontLegs_2" geometry={nodes.FrontLegs_2.geometry} material={materials['9VBattery_Material_006']} skeleton={nodes.FrontLegs_2.skeleton} />
          <skinnedMesh name="FrontLegs_3" geometry={nodes.FrontLegs_3.geometry} material={materials['Material #677']} skeleton={nodes.FrontLegs_3.skeleton} />
        </group>
        <skinnedMesh name="FrontArduinoClamp" geometry={nodes.FrontArduinoClamp.geometry} material={materials['Material #677']} skeleton={nodes.FrontArduinoClamp.skeleton} position={[1.39, 1.35, 0.24]} />
        <skinnedMesh name="Lock" geometry={nodes.Lock.geometry} material={materials['Material #677']} skeleton={nodes.Lock.skeleton} position={[1.12, 0.75, 0.36]} />
        <skinnedMesh name="BackClamp" geometry={nodes.BackClamp.geometry} material={materials.Steel___Satin} skeleton={nodes.BackClamp.skeleton} position={[-4.06, 0.98, 0.23]} rotation={[0, 0, Math.PI / 2]} />
        <group name="ServoBody">
          <skinnedMesh name="ServoBody_1" geometry={nodes.ServoBody_1.geometry} material={materials.Opaque_0_0_255_} skeleton={nodes.ServoBody_1.skeleton} />
          <skinnedMesh name="ServoBody_2" geometry={nodes.ServoBody_2.geometry} material={materials.Opaque_64_64_64_} skeleton={nodes.ServoBody_2.skeleton} />
          <skinnedMesh name="ServoBody_3" geometry={nodes.ServoBody_3.geometry} material={materials.Opaque_165_132_0_} skeleton={nodes.ServoBody_3.skeleton} />
          <skinnedMesh name="ServoBody_4" geometry={nodes.ServoBody_4.geometry} material={materials.Steel___Satin} skeleton={nodes.ServoBody_4.skeleton} />
          <skinnedMesh name="ServoBody_5" geometry={nodes.ServoBody_5.geometry} material={materials.Opaque_198_193_188_} skeleton={nodes.ServoBody_5.skeleton} />
          <skinnedMesh name="ServoBody_6" geometry={nodes.ServoBody_6.geometry} material={materials.Opaque_255_255_255_} skeleton={nodes.ServoBody_6.skeleton} />
        </group>
        <group frustumCulled={false} name="ServoWire" position={[0.28, 0.66, 3.79]} rotation={[Math.PI / 2, 0, Math.PI / 2]}>
          <skinnedMesh name="ServoWire_1" geometry={nodes.ServoWire_1.geometry} material={materials['Material #680']} skeleton={nodes.ServoWire_1.skeleton} />
          <skinnedMesh name="ServoWire_2" geometry={nodes.ServoWire_2.geometry} material={materials['Material #681']} skeleton={nodes.ServoWire_2.skeleton} />
          <skinnedMesh name="ServoWire_3" geometry={nodes.ServoWire_3.geometry} material={materials['Material #682']} skeleton={nodes.ServoWire_3.skeleton} />
        </group>
        <skinnedMesh frustumCulled={false} name="ServoConnector" geometry={nodes.ServoConnector.geometry} material={materials['Material #541']} skeleton={nodes.ServoConnector.skeleton} position={[0.28, 0.66, 3.79]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
        <group name="FrontServoBody">
          <skinnedMesh name="FrontServoBody_1" geometry={nodes.FrontServoBody_1.geometry} material={materials.Opaque_0_0_255_} skeleton={nodes.FrontServoBody_1.skeleton} />
          <skinnedMesh name="FrontServoBody_2" geometry={nodes.FrontServoBody_2.geometry} material={materials.Opaque_64_64_64_} skeleton={nodes.FrontServoBody_2.skeleton} />
          <skinnedMesh name="FrontServoBody_3" geometry={nodes.FrontServoBody_3.geometry} material={materials.Opaque_165_132_0_} skeleton={nodes.FrontServoBody_3.skeleton} />
          <skinnedMesh name="FrontServoBody_4" geometry={nodes.FrontServoBody_4.geometry} material={materials.Steel___Satin} skeleton={nodes.FrontServoBody_4.skeleton} />
          <skinnedMesh name="FrontServoBody_5" geometry={nodes.FrontServoBody_5.geometry} material={materials.Opaque_198_193_188_} skeleton={nodes.FrontServoBody_5.skeleton} />
          <skinnedMesh name="FrontServoBody_6" geometry={nodes.FrontServoBody_6.geometry} material={materials.Opaque_255_255_255_} skeleton={nodes.FrontServoBody_6.skeleton} />
        </group>
        <group name="FrontServoWire" position={[1.81, 0.99, 3.79]} rotation={[0, -1.57, 0]}>
          <skinnedMesh name="FrontServoWire_1" geometry={nodes.FrontServoWire_1.geometry} material={materials['Material #680']} skeleton={nodes.FrontServoWire_1.skeleton} />
          <skinnedMesh name="FrontServoWire_2" geometry={nodes.FrontServoWire_2.geometry} material={materials['Material #681']} skeleton={nodes.FrontServoWire_2.skeleton} />
          <skinnedMesh name="FrontServoWire_3" geometry={nodes.FrontServoWire_3.geometry} material={materials['Material #682']} skeleton={nodes.FrontServoWire_3.skeleton} />
        </group>
        <skinnedMesh frustumCulled={false} name="FrontServoConnector" geometry={nodes.FrontServoConnector.geometry} material={materials['Material #541']} skeleton={nodes.FrontServoConnector.skeleton} position={[1.81, 0.99, 3.79]} rotation={[0, -1.57, 0]} />
        <skinnedMesh name="ArduinoClamp2" geometry={nodes.ArduinoClamp2.geometry} material={materials['Material #677']} skeleton={nodes.ArduinoClamp2.skeleton} position={[0.34, 1.35, 0.24]} rotation={[Math.PI, 0, Math.PI]} />
        <group name="Battery" position={[-0.08, 1.37, 1.04]} scale={[1.2, 1.08, 1.08]}>
          <skinnedMesh name="Battery_1" geometry={nodes.Battery_1.geometry} material={materials['9VBattery_Material']} skeleton={nodes.Battery_1.skeleton} />
          <skinnedMesh name="Battery_2" geometry={nodes.Battery_2.geometry} material={materials['9VBattery_Material_002']} skeleton={nodes.Battery_2.skeleton} />
          <skinnedMesh name="Battery_3" geometry={nodes.Battery_3.geometry} material={materials['9VBattery_Material_006']} skeleton={nodes.Battery_3.skeleton} />
          <skinnedMesh name="Battery_4" geometry={nodes.Battery_4.geometry} material={materials['Material #541']} skeleton={nodes.Battery_4.skeleton} />
        </group>
        <group name="Arduino_Blue2" position={[0.82, 1.82, 0.24]} scale={39.37}>
          <skinnedMesh name="Arduino_Blue2_1" geometry={nodes.Arduino_Blue2_1.geometry} material={materials['19 - Default']} skeleton={nodes.Arduino_Blue2_1.skeleton} />
          <skinnedMesh name="Arduino_Blue2_2" geometry={nodes.Arduino_Blue2_2.geometry} material={materials['20 - Default']} skeleton={nodes.Arduino_Blue2_2.skeleton} />
        </group>
        <group name="ScrewDriver" position={[0, -1.28, 0]} rotation={[Math.PI / 2, 0, 0]}>
          <skinnedMesh name="ScrewDriver_1" geometry={nodes.ScrewDriver_1.geometry} material={materials['9VBattery_Material_002']} skeleton={nodes.ScrewDriver_1.skeleton} />
          <skinnedMesh name="ScrewDriver_2" geometry={nodes.ScrewDriver_2.geometry} material={materials['9VBattery_Material_006']} skeleton={nodes.ScrewDriver_2.skeleton} />
          <skinnedMesh name="ScrewDriver_3" geometry={nodes.ScrewDriver_3.geometry} material={materials['Material #678']} skeleton={nodes.ScrewDriver_3.skeleton} />
          <skinnedMesh name="ScrewDriver_4" geometry={nodes.ScrewDriver_4.geometry} material={materials['Material #679']} skeleton={nodes.ScrewDriver_4.skeleton} />
        </group>
        <skinnedMesh name="UnderClamp01" geometry={nodes.UnderClamp01.geometry} material={materials.Steel___Satin} skeleton={nodes.UnderClamp01.skeleton} position={[-5.09, 0.98, 0.23]} rotation={[0, 0, Math.PI / 2]} />
      </group>
    </group>
  )
}

useGLTF.preload('/assets/ArduinoRobotInstructions2.glb')
