import {  Col, Divider, Image, Row, Switch, Typography } from "antd"

const { Paragraph, Text } = Typography;

export const IdeSetComPortStep = (): JSX.Element => {
    return <Row>
        <Col span={24}>
            <Row>
                <Image preview={false} src="ArduinoIde.png" />
            </Row>
        </Col>
        <Col span={24}>
            <Row>
                <Text>Programmet ska hitta eran Arduino UNO automatiskt.</Text>
            </Row>
        </Col>
        <Divider />
    </Row>
}