import Icon, { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Carousel, Col, Divider, Image, Row, Switch, Typography } from "antd"
import { } from 'antd';
import { CarouselRef } from "antd/es/carousel";
import { useRef, useState } from "react";

const { Paragraph, Text } = Typography;

const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '600px',
    color: '#fff',
    lineHeight: '600px',
    textAlign: 'center',
    background: '#364d79',
};




export const ArduinoServoWireStepCarousel = (): JSX.Element => {

    const carouselRef = useRef<CarouselRef>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    const handlePrevClick = () => {
        if (currentSlide === 0) {
            return; // do nothing if on the first slide
        }
        carouselRef.current?.prev();
    };

    const handleNextClick = () => {
        if (currentSlide === slideTexts.length - 1) {
            return; // do nothing if on the last slide
        }
        carouselRef.current?.next();
    };

    const handleBeforeChange = (current: number, next: number) => {
        setCurrentSlide(next);
    };

    const slideTexts = [
        "Det här är bara en bild för att visa utgångsläget. Klicka på pilen till höger för att se första sladden som ska kopplas in.",
        "Koppla in sladden som har en förgrening och lite eltejp på sig i 5V-pinnen på Arduino. Dom andra ändarna ska vara i strömingången (röd färg) på varje servo.",
        "Koppla en sladd från valfri GND-pin på arduinon till GND-pinen (brun) på den undre servon. Den här sladden behövs för att skapa en sluten krets.",
        "Koppla en sladd från valfri GND-pin på arduinon till GND-pinen (brun) på servon med långa ben.",
        "Koppla en sladd från pin 7 på arduinon till signal-pinnen (gul) på den undre servon.",
        "Koppla en sladd från pin 8 på arduinon till signal-pinnen (gul) på servon med långa ben. Scrolla vidare till nästa steg."
    ];

    return <Row>
        <Col span={18} offset={3}>
            <div style={{width:"100%"}} className="carousel-wrapper">
                <Carousel speed={200} autoplay={false} effect="fade" dots={false} ref={carouselRef} beforeChange={handleBeforeChange}>
                    <div>
                        <Image preview={false} src={"/assets/arduino/01.png"} />
                    </div>
                    <div>
                        <Image preview={false} src={"/assets/arduino/02.png"} />
                    </div>
                    <div>
                        <Image  preview={false} src={"/assets/arduino/03.png"} />
                    </div>
                    <div>
                        <Image  preview={false} src={"/assets/arduino/04.png"} />
                    </div>
                    <div>
                        <Image  preview={false} src={"/assets/arduino/05.png"} />
                    </div>
                    <div>
                        <Image  preview={false} src={"/assets/arduino/06.png"} />
                    </div>
                </Carousel>
                <div className="slide-text">
                    <p style={{textAlign:"center",color:"black"}}>{slideTexts[currentSlide]}</p>
                </div>
                <div className="prev-button">
                    <Button icon={<LeftOutlined/>}  onClick={handlePrevClick}></Button>
                </div>
                <div className="next-button" >
                    <Button icon={<RightOutlined/>} onClick={handleNextClick}></Button>
                </div>
                {/* <button className="next-button" onClick={handleNextClick}>Next</button> */}
            </div>

        </Col>
        <Divider />
    </Row>
}


/*
<Carousel 
                arrows={true}
                dots={true}
                autoplay={false}
                effect={"fade"}
                prevArrow={<SlickArrowLeft currentSlide={0} slideCount={6} />}
                nextArrow={<SlickArrowRight currentSlide={0} slideCount={6} />}
                >
                <div>
                    <h1 style={contentStyle}>sad</h1>
                    { <Image height={700} preview={false} src={"/assets/arduino/01.png"} /> }
                    </div>
                    <div>
                        <h1 style={contentStyle}>asdasfgas</h1>
                        { <Image height={700} preview={false} src={"/assets/arduino/01.png"} /> }
                    </div>
                    {/* <div>
                        <Image height={700} preview={false} src={"/assets/arduino/02.png"} />
                    </div>
                    <div>
                        <Image height={700} preview={false} src={"/assets/arduino/03.png"} />
                    </div>
                    <div>
                        <Image height={700} preview={false} src={"/assets/arduino/04.png"} />
                    </div>
                    <div>
                        <Image height={700} preview={false} src={"/assets/arduino/05.png"} />
                    </div>
                    <div>
                        <Image height={700} preview={false} src={"/assets/arduino/06.png"} />
                    </div> }
                </Carousel>
*/