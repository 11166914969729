
import { Col, Row, Space, Table, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { PowerPointSlideProps } from '../PowerpointComponents/PowerPoint';
const { Paragraph, Text } = Typography;

interface NameProps {
    name:string;
}

const Name = (props:NameProps): JSX.Element => {
    return <p style={{fontSize:"12pt", color:"black" }}>{props.name}</p>
}


export const GroupsSlide = (props: PowerPointSlideProps): JSX.Element => {

    const groups = [
        ["Patrik","Peter","Frej"],
        ["Carl","Christopher"],
        ["Fabian","Nadja"],
        ["Martin","Mattias"],
        ["Robert","Kalle"],
        ["John","Tobbe"],
    ];

    const source = groups.map((x,index) => {
        return {
            key: index+1,
            groupIndex: <Name name={index+1+""}/>,
            name1: <Name name={x[0] ?? ""}/>,
            name2: <Name name={x[1] ?? ""}/>,
            name3: <Name name={x[2] ?? ""}/>,
        }
    });

    const columns = [
        {
            title: 'Groupindex',
            dataIndex: 'groupIndex',
            key: 'groupIndex',
        },
        {
            title: 'Grupper',
            dataIndex: 'name1',
            key: 'name1',
        },
        {
            title: '',
            dataIndex: 'name2',
            key: 'name2',
        },
        {
            title: '',
            dataIndex: 'name3',
            key: 'name3',
        },
    ];


    const url = window.location.href;


    return <Col span={24}>
        <Row>
            <Col span={20} offset={2}>
                <Title style={{ fontSize: "45pt" }} >Grupper</Title>
            </Col>
        </Row>
        <Row gutter={[0, 50]}>
            <Col span={20} offset={2}>
                <Table showHeader={false} size='small' pagination={false} dataSource={source} columns={columns} />
            </Col>
        </Row>
        <Row gutter={[0, 50]}>
            <Col span={20} offset={2}>
                <p style={{ fontSize: "25pt" }}>Instruktioner: <a>{url}</a></p>
            </Col>
        </Row>
    </Col>
}