/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { GroupProps } from '@react-three/fiber';

interface ServoHeadProps extends GroupProps {
  servoRotation: number
}

type GLTFResult = GLTF & {
  nodes: {
    Spinner2_1: THREE.Mesh
    Spinner2_2: THREE.Mesh
  }
  materials: {
    ['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_255_255_255_']: THREE.MeshStandardMaterial
    ServoHead: THREE.MeshStandardMaterial
  }
}


export function ServoHead(props: ServoHeadProps) {
  const { nodes, materials } = useGLTF('/assets/ServoHead.glb') as any
  return (
    <group {...props} dispose={null}>
      <group position={[-0.07, 0.26, 0]} rotation={[0,props.servoRotation,0]}>
        <mesh geometry={nodes.Spinner2_1.geometry} material={materials['SG90 - Micro Servo 9g - Tower Pro v1_Opaque_255_255_255_']} />
        <mesh geometry={nodes.Spinner2_2.geometry} material={materials.ServoHead} />
      </group>
    </group>
  )
}

useGLTF.preload('/assets/ServoHead.glb')
